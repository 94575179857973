import Debugger from "@scripts/core/Debugger.js";

export default class MobileMenuItem {
  #htmlDomElement;
  #parentList;
  #config;

  constructor(htmlDomElement, parentList) {
    this.#htmlDomElement = htmlDomElement;
    this.#parentList = parentList;
    this.#config = {
      openNestedMobileMenuButton: "[data-open-nested-mobile-menu]",
    };
  }

  init() {
    this.handleOpenNextDepth();
  }

  handleOpenNextDepth() {
    let button = this.#htmlDomElement.querySelector(
      this.#config.openNestedMobileMenuButton,
    );

    if (!button) return;

    button.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.saveCurrentListToHistoryBeforeOpenNextDepth();
      this.#parentList.wrapper.closeAllLists();
      this.#parentList.wrapper
        .getWrapperList(button.dataset.openNestedMobileMenu)
        .open();
    });
  }

  saveCurrentListToHistoryBeforeOpenNextDepth() {
    this.#parentList.updateParentState();
  }
}
