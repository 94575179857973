import Config from "@scripts/core/Config.js";

/**
 * Debugger class
 * @description Debug messages - log, info, warn, error. Replace console.log with Debugger.debug and set debugOn to false in production
 * @requires Config
 */
export default class Debugger {
  static debugOn = Config.get("debug");

  /**
   * Debug
   * @param message
   * @param data
   * @param type
   * @param styles
   */
  static debug(message, data = null, type = "log", styles = null) {
    if (!this.debugOn) {
      return;
    }

    const haveStyles = styles !== null;

    if (haveStyles) {
      this.styledConsoleLog(message, data, type, styles);
    } else {
      this.normalConsoleLog(message, data, type);
    }
  }

  static styledConsoleLog(message, data, type, styles) {
    if (console[type]) {
      console[type](`%c${message}`, styles);
      if (data) {
        if (Array.isArray(data)) {
          console[type](...data);
        } else {
          console[type](data);
        }
      }
    }
  }

  static normalConsoleLog(message, data, type) {
    if (console[type]) {
      console[type](message);
      if (data) {
        if (Array.isArray(data)) {
          console[type](...data);
        } else {
          console[type](data);
        }
      }
    }
  }

  static groupLog(messagesArray) {
    if (!this.debugOn) {
      return;
    }

    console.group("Grouped Log");
    messagesArray.forEach(
      ({ message, data = null, type = "log", styles = null }) => {
        if (styles) {
          this.styledConsoleLog(message, data, type, styles);
        } else {
          this.normalConsoleLog(message, data, type);
        }
      },
    );
    console.groupEnd();
  }

  static log(message, data = null) {
    this.debug(message, data, "log");
  }

  static info(message, data = null) {
    this.debug(message, data, "info");
  }

  static warn(message, data = null) {
    this.debug(message, data, "warn");
  }

  static error(message, data = null) {
    this.debug(message, data, "error");
  }
}
