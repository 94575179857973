/**
 * CookieManager
 * @description Manage cookies
 */
export default class CookieManager {
  /**
   * Set cookie
   * @param name
   * @param value
   * @param days
   */
  static set(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }

  /**
   * Get cookie
   * @param name
   * @returns {null|string}
   */
  static get(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  /**
   * Erase cookie
   * @param name
   */
  static erase(name) {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  }

  /**
   * Check if cookie exists
   * @param name
   * @returns {boolean}
   */
  static exists(name) {
    return CookieManager.get(name) !== null;
  }
}
