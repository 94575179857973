import Config from "@scripts/core/Config.js";
import Debugger from "@scripts/core/Debugger.js";

export default class ViewportManager {
  static mobile_breakpoint = Config.get("mobile_breakpoint");

  static isMobile() {
    return window.innerWidth < ViewportManager.mobile_breakpoint;
  }

  static isDesktop() {
    return window.innerWidth >= ViewportManager.mobile_breakpoint;
  }

  static isElementInViewport(el, offset = 0) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    return !(
      (
        rect.bottom < 0 - offset || // Całkowicie powyżej viewportu
        rect.top > windowHeight + offset || // Całkowicie poniżej viewportu
        rect.right < 0 - offset || // Całkowicie na lewo od viewportu
        rect.left > windowWidth + offset
      ) // Całkowicie na prawo od viewportu
    );
  }
}
