import Debugger from "@scripts/core/Debugger.js";

export default class LazyLoader {
  constructor() {
    this.items = [];
    this.eventListeners = {
      scroll: this.onUserInteraction.bind(this, "scroll"),
      mousemove: this.onUserInteraction.bind(this, "mousemove"),
      touchstart: this.onUserInteraction.bind(this, "touchstart"),
    };
    this.autoInitTimeoutInMs = 5000;
    this.setupAutoInit();
    this.init();
  }

  /**
   * Register class to be lazy loaded
   * @param classReference
   * @param className
   * @param args
   */
  registerClass(classReference, className, args = []) {
    this.items.push({
      type: "class",
      reference: classReference,
      name: className,
      args,
      initialized: false,
    });
  }

  /**
   * Register method to be lazy loaded
   * @param methodReference
   * @param methodName
   * @param args
   */
  registerMethod(methodReference, methodName, args = []) {
    this.items.push({
      type: "method",
      reference: methodReference,
      name: methodName,
      args,
      initialized: false,
    });
  }

  /**
   * Initialize lazy loader
   */
  init() {
    setTimeout(() => {
      window.addEventListener("scroll", this.eventListeners.scroll);
      window.addEventListener("mousemove", this.eventListeners.mousemove);
      window.addEventListener("touchstart", this.eventListeners.touchstart);
    }, 50);
  }

  /**
   * Set up automatic initialization timeout
   */
  setupAutoInit() {
    if (this.autoInitTimeoutInMs !== false) {
      setTimeout(() => {
        this.onUserInteraction()
          .then(() => {
            this.removeEventListeners();
          })
          .catch((error) => {
            console.error("Auto init failed:", error);
          });
      }, this.autoInitTimeoutInMs);
    }
  }

  /**
   * Load and initialize items when user interacts with the page
   * @returns {Promise<void>}
   */
  async onUserInteraction(event = null) {
    Debugger.debug(
      `LazyLoader: ${event ? `User interaction: ${event}` : "Auto init"}`,
    );
    for (const item of this.items) {
      if (!item.initialized) {
        item.initialized = true;
        await this.loadAndInitItem(item);
      }
    }
    this.removeEventListeners();
  }

  /**
   * Load and initialize item (class or method)
   * @param item
   * @returns {Promise<void>}
   */
  async loadAndInitItem(item) {
    try {
      if (item.type === "class") {
        await this.loadAndInitClass(item);
      } else if (item.type === "method") {
        await this.loadAndInitMethod(item);
      }
    } catch (error) {
      console.error(
        `Failed to load or initialize ${item.type} ${item.name}:`,
        error,
      );
    }
  }

  /**
   * Load and initialize class
   * @param classInfo
   * @returns {Promise<void>}
   */
  async loadAndInitClass(classInfo) {
    const ClassConstructor = classInfo.reference;
    if (typeof ClassConstructor === "function") {
      const instance = new ClassConstructor(...classInfo.args);
      if (typeof instance.init === "function") {
        instance.init();
      } else {
        console.error(
          `Class ${ClassConstructor.name} does not have an init method`,
        );
      }
    } else {
      console.error(`Failed to load or initialize class ${classInfo.name}`);
    }
  }

  /**
   * Load and initialize method
   * @param methodInfo
   * @returns {Promise<void>}
   */
  async loadAndInitMethod(methodInfo) {
    const method = methodInfo.reference;
    if (typeof method === "function") {
      await method(...methodInfo.args);
    } else {
      console.error(`Failed to load or initialize method ${methodInfo.name}`);
    }
  }

  /**
   * Remove event listeners
   */
  removeEventListeners() {
    window.removeEventListener("scroll", this.eventListeners.scroll);
    window.removeEventListener("mousemove", this.eventListeners.mousemove);
    window.removeEventListener("touchstart", this.eventListeners.touchstart);
  }
}
