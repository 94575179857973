/**
 * Config
 * @type {{debug: boolean, mobile_breakpoint: number}}
 */
const CONFIG = {
  mobile_breakpoint: 1024,
  debug: true,
};

/**
 * Config class
 * @description Get config values from CONFIG
 */
export default class Config {
  static config = CONFIG;

  static get(key) {
    return this.config[key] || null;
  }
}
