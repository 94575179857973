import domReady from "@roots/sage/client/dom-ready";
import LazyLoader from "@scripts/core/LazyLoader.js";
import LiveSearch from "@scripts/components/LiveSearch.js";
import ViewportManager from "@scripts/core/ViewportManager.js";
import DesktopMenu from "@scripts/components/DesktopMenu.js";
import StickyOnScroll from "@scripts/core/StickyOnScroll.js";
import MobileMenu from "@scripts/components/MobileMenu.js";
import Debugger from "@scripts/core/Debugger.js";
import SiteTopbar from "@scripts/components/SiteTopbar.js";

/**
 * Application entrypoint
 */
domReady(async () => {
  const siteTopbar = new SiteTopbar();
  siteTopbar.init();

  const lazyLoader = new LazyLoader();

  if (ViewportManager.isDesktop()) {
    const desktopMenus = document.querySelectorAll(
      '[data-desktop-navigation="root"]',
    );
    if (desktopMenus) {
      desktopMenus.forEach((menu) => {
        lazyLoader.registerClass(DesktopMenu, "DesktopMenu", [menu]);
      });
    }

    let stickyHeader = document.querySelector(
      '[data-element-to-fixed="sticky-header"]',
    );
    if (stickyHeader) {
      new StickyOnScroll(stickyHeader).init();
    }
  }

  if (ViewportManager.isMobile()) {
    const mobileMenus = document.querySelectorAll(
      '[data-mobile-navigation="root"]',
    );
    Debugger.debug("MobileMenu", mobileMenus, "info");
    if (mobileMenus) {
      mobileMenus.forEach((menu) => {
        new MobileMenu(menu).init();
      });
    }
  }

  const searchForm = document.querySelectorAll('[data-live-search="root"]');
  if (searchForm) {
    searchForm.forEach((form) => {
      let desktopSizePx = 800;
      let mobileSizePx = 800;
      lazyLoader.registerClass(LiveSearch, "LiveSearch", [
        form,
        desktopSizePx,
        mobileSizePx,
      ]);
    });
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
