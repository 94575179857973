import Debugger from "@scripts/core/Debugger.js";
import MobileMenuWrapper from "@scripts/components/mobile-menu/MobileMenuWrapper.js";

export default class MobileMenu {
  constructor(menu) {
    this.menu = menu;
    this.config = {
      wrapperDomSelector: "[data-mobile-menu-wrapper]",
    };
    this.mobileMenuWrappers = new Map();
    this.activeWrapper = null;
  }

  init() {
    if (!this.menu) return;
    this.menu.classList.add("is-initialized");

    this.setMobileMenuWrappers();
    this.initMobileMenuWrappers();
  }

  setMobileMenuWrappers() {
    const mobileMenuWrappers = document.querySelectorAll(
      this.config.wrapperDomSelector,
    );

    mobileMenuWrappers.forEach((mobileMenuWrapper) => {
      const slug = mobileMenuWrapper.dataset.mobileMenuWrapper;
      let wrapper = this.getMobileMenuWrapper(slug);
      wrapper.setHtmlElement(mobileMenuWrapper);
    });

    Debugger.debug("MobileMenuWrappers:", this.mobileMenuWrappers, "info");
  }

  getMobileMenuWrapper(slug) {
    if (!this.mobileMenuWrappers.has(slug)) {
      const mobileMenuWrapper = new MobileMenuWrapper(slug, this);
      this.mobileMenuWrappers.set(slug, mobileMenuWrapper);
    }
    return this.mobileMenuWrappers.get(slug);
  }

  initMobileMenuWrappers() {
    this.mobileMenuWrappers.forEach((mobileMenuWrapper) => {
      mobileMenuWrapper.init();
    });
  }

  setActiveWrapper(wrapper) {
    this.activeWrapper = wrapper;
    this.closeWrappers(wrapper);
  }

  closeWrappers(exception) {
    this.mobileMenuWrappers.forEach((wrapper) => {
      if (wrapper !== exception) {
        wrapper.close(false);
      }
    });
  }
}
