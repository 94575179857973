/**
 * DomManager
 * @class
 * @classdesc Class for managing DOM elements
 */
import Debugger from "@scripts/core/Debugger.js";
import ViewportManager from "@scripts/core/ViewportManager.js";

export default class DomManager {
  /**
   * https://github.com/grzesiek1owline/CreateElement
   * Create a DOM node and add attributes, childs and append to parent with one call.
   * @param  {String}  tag  HTML5 tag, like "br" or "b" or "input". document.createElement({tag}) is called.
   * @param  {Object}  attr Tag attributes as json or null or undefined. Example: {style:"height:10px;",class:"className",href:"mailto:"}
   * @param  {Array}   childs Array of childs or null or undefined, can be a string or Element. Example: [_CN("br"),"second line"]
   * @param  {Element} baseNode  ParentNode, where you want add the new node or null or undefined.
   * @return {Element}       Return the new node created
   */
  static createNode(tag, attr, childs, baseNode = null) {
    var el = document.createElement(tag);
    if (typeof attr === "object") {
      for (var a in attr) el.setAttribute(a, attr[a]);
    }
    if (Array.isArray(childs)) {
      childs.forEach((child) => {
        el.appendChild(
          typeof child === "string" || typeof child === "number"
            ? document.createTextNode(child)
            : child,
        );
      });
    }
    if (baseNode !== null) {
      baseNode.appendChild(el);
    }
    return el;
  }

  /**
   * Calculate how many items are missing to better flexbox alignment
   * @param {Element} parent - parent element
   * @param {Number} minItemsWidth - minimum item width
   * @param {Number} itemsCount - number of items
   * @param {Number} itemsOffset - items offset
   * @return {Number} - number of missing items
   */
  static calculateMissingItemsToBetterFlexbox(
    parent,
    minItemsWidth,
    itemsCount,
    itemsOffset,
  ) {
    if (!parent || !minItemsWidth || !itemsCount) {
      return 0;
    }

    // Calculate parent width without padding
    let parentStyle = window.getComputedStyle(parent);
    let parentWidth =
      parent.clientWidth -
      parseFloat(parentStyle.paddingLeft) -
      parseFloat(parentStyle.paddingRight);

    let itemMinWidth = minItemsWidth + itemsOffset;
    let itemsPerRow = Math.floor(parentWidth / itemMinWidth);
    let result = itemsCount % itemsPerRow;

    return result === 0 ? 0 : itemsPerRow - result;
  }

  static setCenteredPositionForAbsoluteElement(element, exceptedSize = 800) {
    if (!element || !(element instanceof Element)) {
      Debugger.debug("Invalid element", null, "error");
      return;
    }

    Debugger.debug("exceptedSize", exceptedSize);

    let elementWidth = DomManager.getElementContentWidth(element);
    let elementPosition = element.getBoundingClientRect();

    let windowW = window.innerWidth;
    let suggestedResultsW = exceptedSize;

    Debugger.debug("suggestedResultW", suggestedResultsW);

    let scale = ViewportManager.isDesktop() ? 0.9 : 1;
    let maxResultsW = windowW * scale;

    let offsetOnLeftSide = elementPosition.left;
    let offsetOnRightSide = windowW - elementPosition.right;

    let resultsW =
      suggestedResultsW > maxResultsW ? maxResultsW : suggestedResultsW;

    let diff = resultsW - elementWidth;
    let left = diff / 2;

    Debugger.debug(element);
    Debugger.debug("offsetOnLeftSide: " + offsetOnLeftSide);
    Debugger.debug("offsetOnRightSide: " + offsetOnRightSide);
    Debugger.debug("left: " + left);

    if (left > offsetOnLeftSide || left > offsetOnRightSide) {
      left = 0;
      resultsW = windowW - 2 * offsetOnLeftSide;
    }

    return {
      left: -1 * left,
      width: resultsW,
    };
  }

  static setCenteredPositionForAbsoluteElementBasedOnParent(
    parent,
    exceptedSize = 800,
  ) {
    if (!parent || !(parent instanceof Element)) {
      Debugger.debug("Invalid parent element", null, "error");
      return;
    }

    Debugger.debug("exceptedSize", exceptedSize);

    // Obliczamy dostępną szerokość w elemencie rodzica
    const parentStyles = window.getComputedStyle(parent);
    const parentPadding =
      parseFloat(parentStyles.paddingLeft) +
      parseFloat(parentStyles.paddingRight);
    const availableWidth = parent.offsetWidth - parentPadding;

    // Określamy maksymalną szerokość na podstawie skali
    const scale = ViewportManager.isDesktop() ? 0.9 : 1;
    const maxWidth = availableWidth * scale;

    // Określamy finalną szerokość modalu
    const resultWidth = Math.min(exceptedSize, maxWidth);

    // Obliczamy offset do wycentrowania
    const leftOffset = (availableWidth - resultWidth) / 2;

    Debugger.debug("availableWidth", availableWidth);
    Debugger.debug("resultWidth", resultWidth);
    Debugger.debug("leftOffset", leftOffset);

    return {
      left: leftOffset,
      width: resultWidth,
    };
  }

  static getElementContentWidth(element) {
    let styles = window.getComputedStyle(element);
    let padding =
      parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);

    return element.clientWidth - padding;
  }

  static getRelativeParentDOMElement(firstParentDOMElement) {
    if (
      firstParentDOMElement &&
      firstParentDOMElement.style &&
      // check inline styles
      firstParentDOMElement.style.position !== "relative" &&
      // check css position
      window.getComputedStyle(firstParentDOMElement).position !== "relative" &&
      // check class relative
      !firstParentDOMElement.classList.contains("relative")
    ) {
      if (firstParentDOMElement.parentElement) {
        return DomManager.getRelativeParentDOMElement(
          firstParentDOMElement.parentElement,
        );
      } else {
        return null; // No relative parent found
      }
    } else {
      return firstParentDOMElement;
    }
  }

  static blockElementForNewEvents(element) {
    if (!element) {
      return;
    }
    element.style.pointerEvents = "none";
  }

  static unblockElementForNewEvents(element) {
    // check element exists
    if (!element) {
      return;
    }
    element.style.pointerEvents = "auto";
  }

  static setTextInputWidthBasedOnCharactersCount(
    input,
    singleCharacterWidth = 9,
  ) {
    input.style.width = input.value.length * singleCharacterWidth + "px";
  }

  static findParentByAttribute(originWrapper, dataSingleProductId) {
    let parent = originWrapper.parentElement;
    while (parent) {
      if (parent.getAttribute(dataSingleProductId)) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  }
}
