import DomManager from "@scripts/core/DomManager.js";

/*
  How use it?
  const loader = new Loader();
  loader.render(document.querySelector('.element'), 'rectangle');
  loader.remove(document.querySelector('.element'));
 */

export default class Loader {
  possible_types = ["rectangle", "button"];

  constructor() {
    this.loader_template = null;
    this.loader = null;
    this.original_content = null;
  }

  getTemplate(type = "rectangle") {
    let loaderType = type;

    if (!this.possible_types.includes(loaderType)) {
      loaderType = "rectangle";
    }

    switch (loaderType) {
      case "rectangle":
        return this.createRectangleLoader();
      case "button":
        return this.createButtonLoader();
      default:
        return null;
    }
  }

  render(parent, type, center = true) {
    this.loader = this.getTemplate(type);

    if (!this.loader) {
      console.error("Loader: loader not found");
      return false;
    }

    this.preventToChangeElementSize(parent);
    this.addElementClass(parent, "is-loading");
    this.saveOriginalContent(parent);
    this.removeChildren(parent);
    parent.appendChild(this.loader);
  }

  saveOriginalContent(element) {
    this.original_content = element.innerHTML;
  }

  restoreOriginalContent(element) {
    element.innerHTML = this.original_content;
  }

  removeChildren(element) {
    element.innerHTML = "";
  }

  toggleElementClass(element, className) {
    element.classList.toggle(className);
  }

  removeElementClass(element, className) {
    element.classList.remove(className);
  }

  addElementClass(element, className) {
    element.classList.add(className);
  }

  preventToChangeElementSize(element) {
    const computedStyle = getComputedStyle(element);
    element.style.height = computedStyle.height;
    element.style.width = computedStyle.width;
  }

  removePreventToChangeElementSize(element) {
    // remove height and width from inline styles
    element.style.height = "";
    element.style.width = "";
  }

  remove(parent, restore = true) {
    // remove loader from parent
    const loader = parent.querySelector("[data-loader]");
    const is_loading_class = parent.classList.contains("is-loading");
    if (loader || is_loading_class) {
      this.removeElementClass(parent, "is-loading");
      if (restore) {
        this.restoreOriginalContent(parent);
      }
      this.removePreventToChangeElementSize(parent);
    }
  }

  createRectangleLoader() {
    return DomManager.createNode(
      "div",
      {
        class: "loader is-rectangle",
        "data-loader": "",
      },
      [
        DomManager.createNode("div"),
        DomManager.createNode("div"),
        DomManager.createNode("div"),
      ],
      null,
    );
  }

  createButtonLoader() {
    return DomManager.createNode(
      "span",
      {
        class: "loader is-rectangle",
        "data-loader": "",
      },
      [
        DomManager.createNode("div"),
        DomManager.createNode("div"),
        DomManager.createNode("div"),
      ],
      null,
    );
  }
}
