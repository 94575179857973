/**
 * Utility class providing common function-related utilities.
 */
class Utils {
  /**
   * Creates a debounced version of the provided function.
   * The debounced function delays the processing of the function until after
   * wait milliseconds have elapsed since the last time it was invoked.
   *
   * @param {Function} fn - The function to debounce.
   * @param {number} wait - The number of milliseconds to delay.
   * @returns {Function} - The debounced function.
   *
   * @example
   * const debouncedFunction = FunctionUtils.debounce(() => {
   *     console.log('Debounced function executed');
   * }, 300);
   * window.addEventListener('resize', debouncedFunction);
   */
  static debounce(fn, wait) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      const args = arguments;
      timeout = setTimeout(() => {
        fn.apply(this, args);
      }, wait || 1);
    };
  }

  /**
   * Creates a throttled version of the provided function.
   * The throttled function ensures the callback is not called more than once
   * every limit milliseconds.
   *
   * @param {Function} callback - The function to throttle.
   * @param {number} limit - The number of milliseconds to limit execution.
   * @returns {Function} - The throttled function.
   *
   * @example
   * const throttledFunction = FunctionUtils.throttle(() => {
   *     console.log('Throttled function executed');
   * }, 1000);
   * window.addEventListener('scroll', throttledFunction);
   */
  static throttle(callback, limit) {
    let wait = false;
    return function () {
      if (!wait) {
        callback.call(this, ...arguments);
        wait = true;
        setTimeout(() => {
          wait = false;
        }, limit);
      }
    };
  }
}

export default Utils;
