import MobileMenuList from "@scripts/components/mobile-menu/MobileMenuList.js";
import Debugger from "@scripts/core/Debugger.js";
import Debbuger from "@scripts/core/Debugger.js";

export default class MobileMenuWrapper {
  root;
  #slug;
  #htmlElement;
  #config;
  #lists = new Map();
  #toggleButton = null;
  #state;
  #history = [];
  #scrollPosition = 0;

  constructor(slug, root) {
    this.root = root;
    this.#slug = slug;

    this.#config = {
      listDomSelector: "[data-mobile-menu-list]",
      initialListSlug: this.#slug,
      toggleClassName: "is-open",
      bodyLockClass: "mobile-menu-open",
    };

    this.#state = {
      isOpen: false,
      currentDepth: 0,
      currentListSlug: this.#config.initialListSlug,
    };
  }

  init() {
    Debugger.debug("MobileMenuWrapper", "init", "info");
    this.setWrapperLists();
    this.initWrapperLists();
    this.initToggleButton();

    Debbuger.debug("MobileMenuWrapper - init", this, "info");
  }

  enableScrollLock() {
    // Store current scroll position
    this.#scrollPosition = window.scrollY || document.documentElement.scrollTop;

    // Add styles to body
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = `-${this.#scrollPosition}px`;
    document.body.style.width = "100%";

    // Add class for additional styling if needed
    document.body.classList.add(this.#config.bodyLockClass);
  }

  disableScrollLock() {
    // Remove styles from body
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("top");
    document.body.style.removeProperty("width");

    // Remove class
    document.body.classList.remove(this.#config.bodyLockClass);

    // Restore scroll position
    window.scrollTo(0, this.#scrollPosition);
  }

  setHtmlElement(el) {
    this.#htmlElement = el;
  }

  setWrapperLists() {
    const mobileMenuLists = this.#htmlElement.querySelectorAll(
      this.#config.listDomSelector,
    );

    console.log("lists", mobileMenuLists);

    mobileMenuLists.forEach((mobileMenuList) => {
      const slug = mobileMenuList.dataset.mobileMenuList;
      const depth = mobileMenuList.dataset.mobileMenuListDepth;

      const list = this.getWrapperList(slug);

      list.setHtmlDomElement(mobileMenuList);
      list.setItems();
      list.initItems();
      list.setDepth(depth);
      list.handleButtons();
    });
  }

  getWrapperList(slug) {
    if (!this.#lists.has(slug)) {
      const mobileMenuList = new MobileMenuList(slug, this);
      this.#lists.set(slug, mobileMenuList);
    }
    return this.#lists.get(slug);
  }

  initWrapperLists() {
    this.#lists.forEach((mobileMenuList) => {
      mobileMenuList.init();
    });
  }

  initToggleButton() {
    this.#toggleButton = this.#htmlElement.querySelector(
      "[data-open-mobile-wrapper]",
    );
    if (!this.#toggleButton) return;

    // check any list exists
    if (this.#lists.size === 0) return;

    this.#toggleButton.addEventListener("click", (e) => {
      e.preventDefault();
      this.toggle();
    });
  }

  toggle() {
    if (this.#state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open(publish = true) {
    this.#state.isOpen = true;
    if (publish) {
      this.publishSlugToRoot(this.#slug);
    }
    this.closeAllLists();
    this.openList(this.#config.initialListSlug);
    this.#htmlElement.classList.add(this.#config.toggleClassName);
    this.enableScrollLock();
  }

  close(publish = true) {
    this.#state.isOpen = false;
    if (publish) {
      this.publishSlugToRoot(null);
    }
    this.closeAllLists();
    this.#htmlElement.classList.remove(this.#config.toggleClassName);
    this.disableScrollLock();
  }

  openList(slug) {
    this.#lists.forEach((mobileMenuList) => {
      if (mobileMenuList.getSlug() === slug) {
        mobileMenuList.open();
      }
    });
  }

  closeAllLists() {
    this.#lists.forEach((mobileMenuList) => {
      mobileMenuList.close();
    });
  }

  publishSlugToRoot(slug) {
    this.root.setActiveWrapper(slug);
  }

  updateState(currentListSlug, currentDepth) {
    this.#state.currentListSlug = currentListSlug;
    this.#state.currentDepth = currentDepth;

    console.warn("MobileMenuWrapper - updateState", this.#state);
    this.updateHistory();
  }

  updateHistory() {
    this.#history.push({
      currentListSlug: this.#state.currentListSlug,
      currentDepth: this.#state.currentDepth,
    });

    console.warn("MobileMenuWrapper - updateHistory", this.#history);
    // Debugger.debug('MobileMenuWrapper - updateHistory', this.#history, 'info');
  }

  goBack() {
    if (this.#history.length === 0) return;

    const lastState = this.#history.pop();
    this.closeAllLists();
    Debugger.debug("MobileMenuWrapper - goBack", lastState, "info");
    this.openList(lastState.currentListSlug);
  }
}
