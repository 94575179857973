import CookieManager from "@scripts/core/CookieManager.js";

export default class SiteTopbar {
  constructor() {
    this.id = "site-topbar";
    this.cookie_expiration = 7; // Cookie expiration in days
    this.topbar = document.getElementById(this.id);
    this.button = document.querySelector(`[aria-controls="${this.id}"]`);
  }

  init() {
    if (!this.button || !this.topbar) return;

    // Check if the cookie exists
    if (CookieManager.get(this.id) !== "closed") {
      this.show();
    }

    this.button.addEventListener("click", () => {
      this.close();
    });
  }

  show() {
    this.topbar.style.display = ""; // Remove inline display style
    this.topbar.dataset.status = "open";
    this.button.setAttribute("aria-expanded", true);
  }

  forceClose() {
    this.topbar.dataset.status = "closed";
    this.button.setAttribute("aria-expanded", false);
    this.topbar.style.display = "none";
  }

  close() {
    this.topbar.dataset.status = "closed";
    this.button.setAttribute("aria-expanded", false);

    // After finishing the animation, hide the topbar
    setTimeout(() => {
      this.topbar.style.display = "none";
    }, 500);

    CookieManager.set(this.id, "closed", this.cookie_expiration);
  }
}
