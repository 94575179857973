import Debugger from "@scripts/core/Debugger.js";

/**
 * Zarządza menu na poziomie desktop.
 */
export default class DesktopMenu {
  /**
   * @param {HTMLElement} menu - Główny element menu.
   */
  constructor(menu) {
    this.menu = menu;
    this.submenus = [];
  }

  /**
   * Inicjalizuje menu i jego podmenu.
   */
  init() {
    if (!this.menu) {
      Debugger.debug("Menu element not found", null, "warn");
      return;
    }
    Debugger.debug("DesktopMenu initialized");

    this.submenus = this.#getSubmenus();
    Debugger.debug("Submenus:", this.submenus, "info");

    this.#initSubmenus();
  }

  /**
   * Pobiera wszystkie elementy podmenu.
   * @returns {NodeList} Lista elementów podmenu.
   */
  #getSubmenus() {
    return this.menu.querySelectorAll("[data-desktop-navigation-depth]");
  }

  /**
   * Inicjalizuje wszystkie podmenu.
   */
  #initSubmenus() {
    const factory = new DesktopSubMenuFactory(this.submenus);
    factory.build();
  }
}

/**
 * Fabryka do tworzenia odpowiednich typów podmenu.
 */
class DesktopSubMenuFactory {
  /**
   * @param {NodeList} submenus - Lista elementów podmenu.
   */
  constructor(submenus) {
    this.submenus = submenus;
  }

  /**
   * Tworzy odpowiednie obiekty podmenu na podstawie ich głębokości.
   */
  build() {
    this.submenus.forEach((submenu) => {
      const depth = parseInt(submenu.dataset.desktopNavigationDepth);
      const SubMenuClass =
        depth === 0 ? DesktopMainSubMenu : DesktopDeeperSubMenu;
      new SubMenuClass(submenu).init();
    });
  }
}

/**
 * Bazowa klasa dla wszystkich typów podmenu.
 */
class BaseSubMenu {
  /**
   * @param {HTMLElement} parent - Element rodzica podmenu.
   */
  constructor(parent) {
    this.parent = parent;
    this.link = null;
    this.submenu_wrapper = null;
    this.is_open = false;
  }

  /**
   * Inicjalizuje podmenu.
   */
  init() {
    if (!this.parent) return;
    this.#getElements();
    this.#handleEvents();
  }

  /**
   * Pobiera potrzebne elementy DOM.
   */
  #getElements() {
    this.link = this.parent.querySelector('[data-desktop-navigation="link"]');
    this.submenu_wrapper = this.parent.querySelector(
      '[data-desktop-navigation="submenu"]',
    );
  }

  /**
   * Obsługuje zdarzenia dla podmenu.
   */
  #handleEvents() {
    this.link.addEventListener("click", this.handleClick.bind(this));
    document.addEventListener("click", this.handleOutsideClick.bind(this));
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  /**
   * Obsługuje kliknięcie w link podmenu.
   * @param {Event} event - Obiekt zdarzenia.
   */
  handleClick(event) {
    event.preventDefault();
    this.toggle();
  }

  /**
   * Obsługuje kliknięcie poza podmenu.
   * @param {Event} event - Obiekt zdarzenia.
   */
  handleOutsideClick(event) {
    if (!this.parent.contains(event.target)) {
      this.close();
    }
  }

  /**
   * Obsługuje naciśnięcie klawisza.
   * @param {KeyboardEvent} event - Obiekt zdarzenia klawiatury.
   */
  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  /**
   * Przełącza stan podmenu (otwarte/zamknięte).
   */
  toggle() {
    this.is_open = !this.is_open;

    if (!this.is_open) {
      this.resetPosition();
    }

    this.parent.classList.toggle("is-active");
    this.submenu_wrapper.classList.toggle("is-active");
    this.checkPosition();
  }

  /**
   * Zamyka podmenu.
   */
  close() {
    this.is_open = false;
    this.parent.classList.remove("is-active");
    this.submenu_wrapper.classList.remove("is-active");
    this.resetPosition();
  }

  resetPosition() {
    this.submenu_wrapper.style.left = "";
    this.submenu_wrapper.style.top = "";
    this.parent.style.left = "";
  }

  /**
   * Sprawdza i dostosowuje pozycję podmenu.
   * Ta metoda powinna być zaimplementowana w klasach pochodnych.
   */
  checkPosition() {
    throw new Error("Method 'checkPosition()' must be implemented.");
  }
}

/**
 * Klasa reprezentująca główne podmenu (głębokość 0).
 */
class DesktopMainSubMenu extends BaseSubMenu {
  /**
   * Sprawdza i dostosowuje pozycję głównego podmenu.
   */
  checkPosition() {
    if (!this.is_open) return;

    const rect = this.submenu_wrapper.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const parentRect = this.parent.getBoundingClientRect();

    let leftPosition = 0;

    if (rect.right > viewportWidth) {
      leftPosition = rect.right - viewportWidth;
    }

    if (rect.right > parentRect.right) {
      leftPosition = rect.right - parentRect.right;
    }

    this.submenu_wrapper.style.left = `-${leftPosition}px`;

    const elementRect = this.submenu_wrapper.getBoundingClientRect();
    Debugger.debug("0 depth element rect", elementRect);

    if (elementRect.left < 0) {
      leftPosition = leftPosition + elementRect.left - 12;
      this.submenu_wrapper.style.left = `-${leftPosition}px`;
    }
  }
}

/**
 * Klasa reprezentująca głębsze podmenu (głębokość > 0).
 */
class DesktopDeeperSubMenu extends BaseSubMenu {
  /**
   * Sprawdza i dostosowuje pozycję głębszego podmenu.
   */
  checkPosition() {
    if (!this.is_open) return;

    const parentWrapper = this.parent.parentNode;
    const parentWrapperRect = parentWrapper.getBoundingClientRect();
    Debugger.debug("parentRect", parentWrapperRect);

    const rect = this.submenu_wrapper.getBoundingClientRect();
    const screenWidth = window.innerWidth;

    if (rect.right > screenWidth) {
      this.submenu_wrapper.style.left = "0";
      this.submenu_wrapper.style.top = `${parentWrapperRect.height}px`;
    }

    Debugger.debug("submenu rect", rect);
  }
}
