import DomManager from "@scripts/core/DomManager.js";
import Loader from "@scripts/core/Loader.js";
import Debugger from "@scripts/core/Debugger.js";
import ViewportManager from "@scripts/core/ViewportManager.js";

export default class LiveSearch {
  constructor(root, exceptedDesktopSize = 750, exceptedMobileSize = 800) {
    this.root = root;
    this.elements = {
      form: null,
      input: null,
      resultModal: null,
      resultItemsWrapper: null,
      loader: null,
    };

    this.config = {
      baseClassName: "live-search",
      itemsOffset: 0, // space between items in css
      minItemWidth: ViewportManager.isDesktop() ? 350 : 220, // this values are used in css (or tailwind) styles of result items elements
      typingTimer: null,
      doneTypingInterval: 600,
      lastValue: "",
      exceptedSize: ViewportManager.isDesktop()
        ? exceptedDesktopSize
        : exceptedMobileSize, // usually excepted size is (2 * item width + paddings of parent (default 16px))
    };
  }

  init() {
    Debugger.debug("LiveSearch: init", this.root);
    if (!this.root) return;

    this.elements.input = this.root.querySelector('[data-live-search="input"]');
    this.elements.form = document.querySelector('[data-live-search="form"]');

    if (!this.elements.input) return;
    if (!this.elements.form) return;

    this.elements.resultModal = this.createResultModal(
      this.root,
      this.config.exceptedSize,
    );
    this.root.appendChild(this.elements.resultModal);

    this.elements.input.addEventListener("keyup", this.handleKeyUp.bind(this));
    this.elements.input.addEventListener(
      "keydown",
      this.handleKeyDown.bind(this),
    );

    this.#addEventListeners();
  }

  #addEventListeners() {
    document.addEventListener("click", (e) => {
      if (!this.elements.resultModal.contains(e.target)) {
        this.hideResults();
      }
    });

    document.addEventListener("click", (e) => {
      if (e.target.id === "close-live-search-results") {
        this.hideResults();
      }
    });

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.hideResults();
      }
    });
  }

  createResultModal(parent, expectedSize) {
    let position = DomManager.setCenteredPositionForAbsoluteElement(
      parent,
      expectedSize,
    );

    Debugger.debug("position", position);

    let html = DomManager.createNode(
      "div",
      {
        class: `${this.config.baseClassName}__result-modal`,
        id: "live-search-dynamic-result",
        style: "width:" + position.width + "px; left:" + position.left + "px;",
      },
      [],
      parent,
    );
    return html;
  }

  updateSizeAndPosition(parent, element, expectedSize, units = "px") {
    if (!element || !parent) return;

    if (units !== "%" && units !== "px") {
      Debugger.error("updateSizeAndPosition: wrong units");
      return;
    }

    if (units === "%") {
      expectedSize = (expectedSize * parent.clientWidth) / 100;
    }

    let position = DomManager.setCenteredPositionForAbsoluteElement(
      parent,
      expectedSize,
    );

    element.style.width = position.width + "px";
    element.style.left = position.left + "px";
  }

  handleKeyDown() {
    if (this.elements.input.value !== this.config.lastValue) {
      clearTimeout(this.config.typingTimer);
    }
  }

  handleKeyUp() {
    const value = this.elements.input.value;

    if (value === this.config.lastValue) return;

    if (value.length <= 2) {
      this.clearResults();
      this.hideResults();
      this.config.lastValue = "";
      return;
    }

    this.config.lastValue = value;

    clearTimeout(this.config.typingTimer);
    this.config.typingTimer = setTimeout(() => {
      this.submitForm(value);
    }, this.config.doneTypingInterval);
  }

  submitForm(value) {
    let formData = new FormData();
    formData.append("search", value);
    formData.append("nonce", window.app.nonce);
    formData.append("action", "sage_live_search");

    this.clearResults();
    this.toggleLoader(this.elements.resultModal, true);
    this.showResults();

    fetch(window.app.ajaxUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        let count = data.data.count ?? 0;
        let responseHTML = data.data.html;

        if (count > 0) {
          this.updateSizeAndPosition(
            this.root,
            this.elements.resultModal,
            this.config.exceptedSize,
          );
          this.updateResultsContent(responseHTML);

          this.elements.resultItemsWrapper =
            this.elements.resultModal.querySelector(
              "[data-live-search='result-items-wrapper']",
            );

          let needFakeItems = DomManager.calculateMissingItemsToBetterFlexbox(
            this.elements.resultModal,
            this.config.minItemWidth,
            count,
            this.config.itemsOffset,
          );

          if (needFakeItems > 0 && this.elements.resultItemsWrapper) {
            for (let i = 0; i < needFakeItems; i++) {
              DomManager.createNode(
                "div",
                {
                  class: `${this.config.baseClassName}__fake-item`,
                  style: "min-width:" + this.config.minItemWidth + "px;",
                },
                [],
                this.elements.resultItemsWrapper,
              );
            }
          }
        } else {
          this.clearResults();
          this.updateSizeAndPosition(
            this.root,
            this.elements.resultModal,
            100,
            "%",
          );
          this.updateResultsContent(responseHTML);
        }
      })
      .catch((error) => console.error(error));
  }

  showResults() {
    this.elements.resultModal.style.display = "flex";
  }

  hideResults() {
    this.elements.resultModal.style.display = "none";
  }

  updateResultsContent(content) {
    this.elements.resultModal.innerHTML = "";
    this.elements.resultModal.innerHTML = content;
  }

  clearResults() {
    this.elements.resultModal.innerHTML = "";
  }

  toggleLoader(parent, loading = true) {
    if (loading) {
      this.elements.loader = new Loader();
      this.elements.loader.render(parent);
    } else {
      this.elements.loader.remove(parent);
    }
  }
}
