import MobileMenuItem from "@scripts/components/mobile-menu/MobileMenuItem.js";
import Debugger from "@scripts/core/Debugger.js";

export default class MobileMenuList {
  #slug;
  #config;
  #items = [];
  wrapper;
  #depth;
  #htmlDomElement;

  constructor(slug, wrapper) {
    this.#slug = slug;
    this.#depth = 0;
    this.wrapper = wrapper;
    this.#htmlDomElement = null;
    this.#config = {
      itemDomSelector: "[data-mobile-menu-item]",
      goBackButtonSelector: "[data-mobile-menu-go-to-prev]",
      forceCloseButtonSelector: "[data-mobile-menu-force-close]",
      toggleClass: "is-open",
    };
    this.#items = [];
  }

  setHtmlDomElement(el) {
    this.#htmlDomElement = el;
  }

  setDepth(depth) {
    this.#depth = depth;
  }

  init() {}

  setItems() {
    const mobileMenuItems = this.#htmlDomElement.querySelectorAll(
      this.#config.itemDomSelector,
    );

    mobileMenuItems.forEach((mobileMenuItem) => {
      this.#items.push(new MobileMenuItem(mobileMenuItem, this));
    });
  }

  initItems() {
    this.#items.forEach((mobileMenuItem) => {
      mobileMenuItem.init();
    });
  }

  handleButtons() {
    const goToPrevButton = this.#htmlDomElement.querySelector([
      this.#config.goBackButtonSelector,
    ]);
    const forceCloseButton = this.#htmlDomElement.querySelector([
      this.#config.forceCloseButtonSelector,
    ]);

    if (goToPrevButton) {
      goToPrevButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.wrapper.goBack();
      });
    }

    if (forceCloseButton) {
      forceCloseButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.wrapper.close();
      });
    }
  }

  open() {
    if (!this.#htmlDomElement) {
      return;
    }
    this.#htmlDomElement.classList.add(this.#config.toggleClass);
  }

  close() {
    if (!this.#htmlDomElement) {
      return;
    }
    this.#htmlDomElement.classList.remove(this.#config.toggleClass);
  }

  updateParentState() {
    this.wrapper.updateState(this.#slug, this.#depth);
  }

  getSlug() {
    return this.#slug;
  }
}
