import FunctionUtils from "@scripts/core/Utils.js";
import ViewportManager from "@scripts/core/ViewportManager.js";
import Debugger from "@scripts/core/Debugger.js";

export default class StickyOnScroll {
  constructor(el) {
    this.el = el;
    this.isSticky = false;
    this.elInitAttributes = el?.getAttribute("style") || "";
    this.elHeight = el?.offsetHeight || 0;
    this.elParent = el?.parentElement || null;
    this.currentScrollPosition = 0;
    this.windowHeight = window.innerHeight;
    this.offset = 400;

    this.debouncedFunction = FunctionUtils.debounce(
      this.#handleScroll.bind(this),
      50,
    );
  }

  init() {
    if (!this.el) return;

    this.currentScrollPosition = this.#getCurrentScrollPosition();
    window.addEventListener("scroll", this.debouncedFunction);
  }

  #getCurrentScrollPosition() {
    return window.scrollY;
  }

  #handleScroll() {
    this.currentScrollPosition = this.#getCurrentScrollPosition();

    if (
      !ViewportManager.isElementInViewport(this.elParent) &&
      this.currentScrollPosition > this.offset &&
      !this.isSticky
    ) {
      Debugger.debug("Sticky element is not in viewport", "info");
      this.#makeSticky();
    } else if (this.currentScrollPosition < this.offset && this.isSticky) {
      Debugger.debug("Unsticky!", "info");
      this.#makeUnsticky();
    }
  }

  #makeSticky() {
    const { el, elHeight, elParent } = this;
    el.style.position = "fixed";
    el.style.top = 0;
    el.style.left = 0;
    el.style.right = 0;
    el.style.zIndex = 1000;
    el.style.transform = "translateY(-300px)";

    setTimeout(() => {
      el.style.transition = "transform 0.3s";
      el.style.transform = "translateY(0)";
    }, 100);

    if (elParent) {
      elParent.style.height = `${elHeight}px`;
    }
    this.isSticky = true;
  }

  #makeUnsticky() {
    const { el, elParent, elInitAttributes } = this;

    if (elInitAttributes) {
      el.setAttribute("style", elInitAttributes);
    } else {
      el.style.position = "";
      el.style.top = "";
      el.style.left = "";
      el.style.right = "";
      el.style.zIndex = "";
      el.style.transition = "";
      el.style.transform = "";
    }

    if (elParent) {
      elParent.style.height = "auto";
    }
    this.isSticky = false;
  }
}
